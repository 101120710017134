import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Partner } from '@dougs/partners/dto';

@Injectable({
  providedIn: 'root',
})
export class FileHttpService {
  constructor(private readonly http: HttpClient) {}

  deleteFile(company: Company, file: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/attachments/${file.id}`,
    );
  }

  uploadFile(company: Company, fileType: string | undefined, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/attachments?filename=${file.name}&type=${fileType}`,
      formData,
    );
  }

  uploadPartnerFile(partner: Partner, fileType: string, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}/attachments?filename=${file.name}&type=${fileType}`,
      formData,
    );
  }

  deletePartnerFile(partner: Partner, attachment: Attachment): Observable<Partner> {
    return this.http.delete<Partner>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}/attachments/${attachment.id}`,
    );
  }

  uploadNaturalPersonFile(partner: Partner, fileType: string, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}/natural-people/${partner.naturalPersonId}/attachments?filename=${file.name}&type=${fileType}`,
      formData,
    );
  }

  deleteNaturalPersonFile(partner: Partner, attachment: Attachment): Observable<Partner> {
    return this.http.delete<Partner>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}/natural-people/${partner.naturalPersonId}/attachments/${attachment.id}`,
    );
  }
}
