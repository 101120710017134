import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { LoggerService } from '@dougs/core/logger';
import { ChangeHistory } from '@dougs/operations/dto';
import { IdentityDocument, Partner, PartnerFieldDto, PartnerPositionKey } from '@dougs/partners/dto';

@Injectable({
  providedIn: 'root',
})
export class PartnerHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly log: LoggerService,
  ) {}

  getPartners(companyId: number, isClient = true, companyHasVendorsAllowed = false): Observable<Partner[]> {
    let params: HttpParams = new HttpParams();
    if (!isClient) {
      params = params.append('isClient', false);
    }
    if (companyHasVendorsAllowed) {
      params = params.append('isVendor', false).append('includeMultiplePositionsVendorsPartners', true);
    }
    return this.http.get<Partner[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/partners`, {
      params,
    });
  }

  getPartnersVendor(companyId: number): Observable<Partner[]> {
    return this.http.get<Partner[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/partners/get-vendors`,
    );
  }

  getVendorsForSettings(companyId: number, getShortList: boolean): Observable<Partner[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('isVendor', 'true');
    if (getShortList) {
      params = params.append('limit', 5);
    }
    return this.http.get<Partner[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/partners`, {
      params,
    });
  }

  updatePartner(partner: Partner): Observable<Partner> {
    return this.http.post<Partner>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}`,
      partner,
    );
  }

  getPartner(partnerId: number, companyId: number): Observable<Partner> {
    return this.http.get<Partner>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/partners/${partnerId}`,
    );
  }

  deletePartner(partnerId: number, companyId: number): Observable<Partner> {
    return this.http.delete<Partner>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/partners/${partnerId}`,
    );
  }

  createPartner(
    partner: { companyId: number; type: string } & Partial<Partner>,
    companyId: number,
  ): Observable<Partner> {
    return this.http.post<Partner>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/partners`, {
      ...partner,
    });
  }

  getPartnerChangesHistory(companyId: number, partnerId: number): Observable<ChangeHistory[]> {
    return this.http.get<ChangeHistory[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/partners/${partnerId}/changes`,
    );
  }

  getMissingFieldsPartnersForESignProcedure(companyId: number, partnerIds: number[]): Observable<PartnerFieldDto[]> {
    return this.http.post<PartnerFieldDto[]>(
      `${AppConfig.settings.legacyApiServerUrl}/yousign/${companyId}/partners-missing-fields`,
      {
        partnerIds,
      },
    );
  }

  getForeignPartners(companyId: number, positions?: PartnerPositionKey[]): Observable<Partner[]> {
    let params: HttpParams = new HttpParams();
    if (positions) {
      positions.forEach((position) => {
        params = params.append('positions', position);
      });
    }
    return this.http.get<Partner[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/foreign-partners`,
      {
        params,
      },
    );
  }

  checkIdentityDocument(naturalPersonId: number): Observable<IdentityDocument[]> {
    return this.http.post<IdentityDocument[]>(`${AppConfig.settings.legacyApiServerUrl}/id-check`, {
      naturalPersonId: naturalPersonId,
    });
  }
}
