import { Injectable } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { Partner } from '@dougs/partners/dto';
import { Profile } from '@dougs/user/dto';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  addNaturalPersonMultipleAttachmentFromKey(
    partner: Partner,
    attachments: Attachment[],
    modelFileKey: keyof Profile,
  ): Partner {
    const currentAttachments: Attachment[] = (partner.naturalPerson[modelFileKey] as unknown as Attachment[]) ?? [];
    return {
      ...partner,
      naturalPerson: {
        ...partner.naturalPerson,
        [modelFileKey]: [...currentAttachments, ...attachments],
      },
    };
  }

  addNaturalPersonSingleAttachmentFromKey(
    partner: Partner,
    attachment: Attachment,
    naturalPersonKey: keyof Profile,
  ): Partner {
    return {
      ...partner,
      naturalPerson: {
        ...partner.naturalPerson,
        [naturalPersonKey]: attachment,
      },
    };
  }

  removeNaturalPersonMultipleAttachments(partner: Partner, attachment: Attachment, modelKey: keyof Profile) {
    const updatedAttachments: Attachment[] = (partner.naturalPerson[modelKey] as unknown as Attachment[])?.filter(
      (currentAttachment) => currentAttachment.id !== attachment.id,
    );
    return {
      ...partner,
      naturalPerson: {
        ...partner.naturalPerson,
        [modelKey]: updatedAttachments,
      },
    };
  }

  removeNaturalPersonSingleAttachment(partner: Partner, attachment: Attachment, modelKey: keyof Profile) {
    return {
      ...partner,
      naturalPerson: {
        ...partner.naturalPerson,
        [modelKey]: null,
      },
    };
  }

  addPartnerMultipleAttachmentFromKey(
    partner: Partner,
    attachments: Attachment[],
    modelFileKey: keyof Partner,
  ): Partner {
    const currentAttachments: Attachment[] = (partner[modelFileKey] as Attachment[]) ?? [];
    return {
      ...partner,
      [modelFileKey]: [...currentAttachments, ...attachments],
    };
  }

  addPartnerSingleAttachmentFromKey(
    partner: Partner,
    attachment: Attachment,
    naturalPersonKey: keyof Partner,
  ): Partner {
    return {
      ...partner,
      [naturalPersonKey]: attachment,
    };
  }

  removePartnerMultipleAttachments(partner: Partner, attachment: Attachment, modelKey: keyof Partner) {
    const updatedAttachments: Attachment[] = (partner[modelKey] as Attachment[])?.filter(
      (currentAttachment) => currentAttachment.id !== attachment.id,
    );
    return {
      ...partner,
      [modelKey]: updatedAttachments,
    };
  }

  removePartnerSingleAttachment(partner: Partner, attachment: Attachment, modelKey: keyof Partner) {
    return {
      ...partner,
      [modelKey]: null,
    };
  }

  addLegalPersonMultipleAttachmentFromKey(
    legalPartner: Partner,
    attachments: Attachment[],
    modelFileKey: keyof Company,
  ): Partner {
    const currentAttachments: Attachment[] = (legalPartner.legalPerson[modelFileKey] as Attachment[]) ?? [];
    return {
      ...legalPartner,
      legalPerson: {
        ...legalPartner.legalPerson,
        [modelFileKey]: [...currentAttachments, ...attachments],
      },
    };
  }

  addLegalPersonSingleAttachmentFromKey(
    legalPartner: Partner,
    attachment: Attachment,
    naturalPersonKey: keyof Company,
  ): Partner {
    return {
      ...legalPartner,
      legalPerson: {
        ...legalPartner.legalPerson,
        [naturalPersonKey]: attachment,
      },
    };
  }

  removeLegalPersonMultipleAttachments(legalPartner: Partner, attachment: Attachment, modelKey: keyof Company) {
    const updatedAttachments: Attachment[] = (legalPartner.legalPerson[modelKey] as Attachment[])?.filter(
      (currentAttachment) => currentAttachment.id !== attachment.id,
    );
    return {
      ...legalPartner,
      legalPerson: {
        ...legalPartner.legalPerson,
        [modelKey]: updatedAttachments,
      },
    };
  }

  removeLegalPersonSingleAttachment(legalPartner: Partner, attachment: Attachment, modelKey: keyof Company) {
    return {
      ...legalPartner,
      legalPerson: {
        ...legalPartner.legalPerson,
        [modelKey]: null,
      },
    };
  }
}
