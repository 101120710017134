import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { IdentityDocument, Partner, PartnerFieldDto, PartnerPositionKey } from '@dougs/partners/dto';
import { Profile } from '@dougs/user/dto';
import { AvatarHttpService } from '../http/avatar.http';
import { FileHttpService } from '../http/file.http';
import { PartnerHttpService } from '../http/partner.http';
import { AttachmentService } from '../services/attachment.service';

interface PartnerState {
  partners: Partner[];
  foreignPartners: Partner[];
  vendorsSettings: Partner[];
  vendors: Partner[];
}

@Injectable({
  providedIn: 'root',
})
export class PartnerStateService extends StateService<PartnerState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly partnerHttpService: PartnerHttpService,
    private readonly avatarHttpService: AvatarHttpService,
    private readonly fileHttpService: FileHttpService,
    private readonly attachmentService: AttachmentService,
  ) {
    super();
  }

  readonly partners$: Observable<Partner[]> = this.select((state) => state.partners);
  readonly vendorsSettings$: Observable<Partner[]> = this.select((state) => state.vendorsSettings);
  readonly vendors$: Observable<Partner[]> = this.select((state) => state.vendors);
  readonly foreignPartners$: Observable<Partner[]> = this.select((state) => state.foreignPartners);
  readonly partnersAssociateCeoDirector$: Observable<Partner[]> = this.select(
    (state) => state.partners?.filter((partner) => partner.isAssociate || partner.isCeo || partner.isDirector) ?? [],
  );
  readonly associatePartners$: Observable<Partner[]> = this.select(
    (state) => state.partners?.filter((partner) => partner.isAssociate) ?? [],
  );
  readonly affiliatePartners$: Observable<Partner[]> = this.select(
    (state) => state.partners?.filter((partner) => partner.isAffiliate) ?? [],
  );
  readonly employeePartners$: Observable<Partner[]> = this.select(
    (state) => state.partners?.filter((partner) => partner.isEmployee) ?? [],
  );
  readonly physicalPartners$: Observable<Partner[]> = this.select(
    (state) => state.partners?.filter((partner) => !!partner.naturalPersonId) ?? [],
  );
  readonly legalPartners$: Observable<Partner[]> = this.select(
    (state) => state.partners?.filter((partner) => !!partner.legalPersonId) ?? [],
  );
  readonly allCurrentPartnersButAssociates$: Observable<Partner[]> = this.select(
    (state) =>
      state.partners?.filter((partner) => {
        const canPartnerBeAssociate = partner.positions?.find(
          (position) => position.key === 'isAssociate' && !position.isActive && position.isAvailable,
        );
        const isPartnerStillActive =
          partner.positions?.some((position) => !position.isEnded && position.isActive) ||
          partner.positions?.every((position) => !position.isActive);

        return canPartnerBeAssociate && isPartnerStillActive;
      }) ?? [],
  );

  private updatePartnerAndVendorState(partnerOrVendor: Partner): void {
    this.setState({
      partners: this.state.partners.map((partner) => (partner.id === partnerOrVendor.id ? partnerOrVendor : partner)),
      vendorsSettings:
        this.state.vendorsSettings?.map((vendor) => (vendor.id === partnerOrVendor.id ? partnerOrVendor : vendor)) ??
        [],
    });
  }

  private deletePartnerAndVendorFromState(partnerOrVendor: Partner): void {
    this.setState({
      partners: this.state.partners.filter((currentPartner) => currentPartner.id !== partnerOrVendor.id),
      vendorsSettings:
        this.state.vendorsSettings?.filter((currentPartner) => currentPartner.id !== partnerOrVendor.id) ?? [],
    });
  }

  private addPartnerOrVendorToState(newPartnerOrVendor: Partner): void {
    if (newPartnerOrVendor.isVendor) {
      this.setState({
        vendorsSettings: [...(this.state?.vendorsSettings ?? []), newPartnerOrVendor],
      });
    } else {
      this.setState({
        partners: [...this.state.partners, newPartnerOrVendor],
      });
    }
  }

  async refreshVendorsSettings(companyId: number, getShortList: boolean): Promise<void> {
    try {
      this.setState({
        vendorsSettings: await lastValueFrom(this.partnerHttpService.getVendorsForSettings(companyId, getShortList)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshPartners(companyId: number, companyHasVendorsAllowed = false): Promise<void> {
    try {
      this.setState({
        partners: await lastValueFrom(
          this.partnerHttpService.getPartners(companyId, companyId !== 125, companyHasVendorsAllowed),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getPartner(partnerId: number, companyId: number): Promise<void> {
    try {
      const enrichedPartner: Partner = await lastValueFrom(this.partnerHttpService.getPartner(partnerId, companyId));
      this.updatePartnerAndVendorState(enrichedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updatePartner(partner: Partner): Promise<void> {
    try {
      await lastValueFrom(this.partnerHttpService.updatePartner(partner));
      const refreshedPartner: Partner = await lastValueFrom(
        this.partnerHttpService.getPartner(partner.id, partner.companyId),
      );
      this.updatePartnerAndVendorState(refreshedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async createPartner(partnerType: string, companyId: number, data: Partial<any> = {}): Promise<Partner | null> {
    try {
      const partnerToCreate: { companyId: number; type: string } = {
        ...data,
        companyId: companyId,
        type: partnerType,
      };
      const createdPartner: Partner = await lastValueFrom(
        this.partnerHttpService.createPartner(partnerToCreate, companyId),
      );
      this.addPartnerOrVendorToState(createdPartner);
      return createdPartner;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async deletePartner(partner: Partner): Promise<void> {
    try {
      await lastValueFrom(this.partnerHttpService.deletePartner(partner.id, partner.companyId));
      this.deletePartnerAndVendorFromState(partner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshVendors(company: Company): Promise<void> {
    try {
      this.setState({
        vendors: await lastValueFrom(this.partnerHttpService.getPartnersVendor(company.id)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAvatar(partner: Partner, file: File): Promise<boolean> {
    try {
      await lastValueFrom(this.avatarHttpService.uploadPartnerAvatar(partner, file));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async uploadLegalPersonAvatar(legalPartner: Partner, file: File): Promise<boolean> {
    try {
      await lastValueFrom(this.fileHttpService.uploadFile(legalPartner.legalPerson, 'avatar', file));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async uploadPartnerAttachments(partner: Partner, files: FileList, fileType: string, modelKey: string): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.fileHttpService.uploadPartnerFile(partner, fileType, file)),
        ),
      );
      const updatedPartner: Partner = this.attachmentService.addPartnerMultipleAttachmentFromKey(
        partner,
        attachments,
        modelKey as keyof Partner,
      );
      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadNaturalPersonAttachments(
    partner: Partner,
    files: FileList,
    fileType: string,
    modelKey: string,
  ): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.fileHttpService.uploadNaturalPersonFile(partner, fileType, file)),
        ),
      );

      const updatedPartner: Partner = this.attachmentService.addNaturalPersonMultipleAttachmentFromKey(
        partner,
        attachments,
        modelKey as keyof Profile,
      );
      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadLegalParterAttachments(
    legalPartner: Partner,
    files: FileList,
    fileType: string,
    modelKey: string,
  ): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.fileHttpService.uploadFile(legalPartner.legalPerson, fileType, file)),
        ),
      );
      const updatedPartner: Partner = this.attachmentService.addLegalPersonMultipleAttachmentFromKey(
        legalPartner,
        attachments,
        modelKey as keyof Company,
      );
      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadPartnerAttachment(partner: Partner, file: File, fileType: string, modelKey: string): Promise<void> {
    try {
      const attachment: Attachment = await lastValueFrom(
        this.fileHttpService.uploadPartnerFile(partner, fileType, file),
      );
      const updatedPartner: Partner = this.attachmentService.addPartnerSingleAttachmentFromKey(
        partner,
        attachment,
        modelKey as keyof Partner,
      );
      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadNaturalPersonAttachment(partner: Partner, file: File, fileType: string, modelKey: string): Promise<void> {
    try {
      const attachment: Attachment = await lastValueFrom(
        this.fileHttpService.uploadNaturalPersonFile(partner, fileType, file),
      );
      const updatedPartner: Partner = this.attachmentService.addNaturalPersonSingleAttachmentFromKey(
        partner,
        attachment,
        modelKey as keyof Profile,
      );
      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadLegalPersonAttachment(
    legalPartner: Partner,
    file: File,
    fileType: string,
    modelKey: string,
  ): Promise<void> {
    try {
      const attachment: Attachment = await lastValueFrom(
        this.fileHttpService.uploadFile(legalPartner.legalPerson, fileType, file),
      );
      const updatedPartner: Partner = this.attachmentService.addLegalPersonSingleAttachmentFromKey(
        legalPartner,
        attachment,
        modelKey as keyof Company,
      );
      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removePartnerAttachments(
    partner: Partner,
    attachment: Attachment,
    fileType: string,
    modelKey: string,
    allowMultiple: boolean,
  ): Promise<void> {
    try {
      await lastValueFrom(this.fileHttpService.deletePartnerFile(partner, attachment));

      const updatedPartner: Partner = allowMultiple
        ? this.attachmentService.removePartnerMultipleAttachments(partner, attachment, modelKey as keyof Partner)
        : this.attachmentService.removePartnerSingleAttachment(partner, attachment, modelKey as keyof Partner);

      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeNaturalPersonAttachments(
    partner: Partner,
    attachment: Attachment,
    fileType: string,
    modelKey: string,
    allowMultiple: boolean,
  ): Promise<void> {
    try {
      await lastValueFrom(this.fileHttpService.deleteNaturalPersonFile(partner, attachment));

      const updatedPartner: Partner = allowMultiple
        ? this.attachmentService.removeNaturalPersonMultipleAttachments(partner, attachment, modelKey as keyof Profile)
        : this.attachmentService.removeNaturalPersonSingleAttachment(partner, attachment, modelKey as keyof Profile);

      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeLegalPersonAttachments(
    legalPartner: Partner,
    attachment: Attachment,
    fileType: string,
    modelKey: string,
    allowMultiple: boolean,
  ): Promise<void> {
    try {
      await lastValueFrom(this.fileHttpService.deleteFile(legalPartner.legalPerson, attachment));

      const updatedPartner: Partner = allowMultiple
        ? this.attachmentService.removeLegalPersonMultipleAttachments(
            legalPartner,
            attachment,
            modelKey as keyof Company,
          )
        : this.attachmentService.removeLegalPersonSingleAttachment(legalPartner, attachment, modelKey as keyof Company);

      this.updatePartnerAndVendorState(updatedPartner);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getPartnerHistoryChanges(partner: Partner) {
    try {
      return await lastValueFrom(this.partnerHttpService.getPartnerChangesHistory(partner.companyId, partner.id));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async getMissingFieldsPartnersForESignProcedure(companyId: number, partnerIds: number[]): Promise<PartnerFieldDto[]> {
    try {
      return await lastValueFrom(
        this.partnerHttpService.getMissingFieldsPartnersForESignProcedure(companyId, partnerIds),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async refreshForeignPartners(companyId: number, positions?: PartnerPositionKey[]): Promise<void> {
    try {
      this.setState({
        foreignPartners: await lastValueFrom(this.partnerHttpService.getForeignPartners(companyId, positions)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async checkIdentityDocument(naturalPersonId: number): Promise<IdentityDocument[] | null> {
    try {
      return await lastValueFrom(this.partnerHttpService.checkIdentityDocument(naturalPersonId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
