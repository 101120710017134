import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Partner } from '@dougs/partners/dto';

@Injectable({
  providedIn: 'root',
})
export class AvatarHttpService {
  constructor(private readonly http: HttpClient) {}

  uploadPartnerAvatar(partner: Partner, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}/natural-people/${partner.naturalPersonId}/attachments?filename=${file.name}&type=avatar`,
      formData,
    );
  }
}
